<template>
  <div class="flex items-center mt-2">
    <div class="flex items-center w-56 select-none text-sm leading-tight">
      {{ filter.label }}
    </div>
    <el-input
      v-model="textData"
      clearable
      autocorrect="off"
      autocapitalize="off"
      autocomplete="nope"
      spellcheck="false"
      :disabled="disabled"
      :placeholder="filter.placeholder"
      :maxlength="inputLimit"
      @clear="handleFilterInput"
      @update:modelValue="handleFilterInput"
    />
  </div>
</template>

<script>
import typeOf from 'just-typeof'
export default {
  props: {
    filter: {
      type: Object,
      required: true
    },
    tags: {
      type: Object,
      required: true
    },
    modelValue: {
      type: [String, Number],
      required: false,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      textData: this.modelValue || null
    }
  },
  computed: {
    inputLimit() {
      return this.filter.maxLength || null
    },
    disabled() {
      const disable = this.filter && this.filter.disable
      if (disable && typeOf(disable) === 'function') {
        const isDisabled = disable(this.tags)
        if (isDisabled) {
          if (this.textData) {
            this.setToNull()
          }
          return isDisabled
        }
      }
      return false
    }
  },
  watch: {
    textData(newVal) {
      if (
        this.inputLimit !== null &&
        newVal &&
        newVal.length > this.inputLimit
      ) {
        this.textData = newVal.slice(0, this.inputLimit)
      }
    }
  },
  methods: {
    handleFilterInput(v) {
      let value = this.textData || ''
      if (this.inputLimit !== null && value.length > this.inputLimit) {
        value = value.slice(0, this.inputLimit)
      }
      this.$emit('update:modelValue', value)
    },
    setToNull() {
      this.$emit('update:modelValue', null)
      this.textData = null
    }
  }
}
</script>
